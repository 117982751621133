import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector, useStore } from "react-redux";
import styled from "styled-components";
import AiComponentEditorWrapper from "ai/AiComponentEditor/AiComponentEditor";
import { FullPageSpinner } from "components/ui/FullPageSpinner";
import envs from "env";
import { useInterval } from "hooks/ui/useInterval";
import { usePauseTimersOnTabSleep } from "hooks/ui/usePauseTimersOnTabSleep";
import { appFrameLoaded } from "legacy/actions/evaluationActions";
import { setMetaProp, setMetaProps } from "legacy/actions/metaActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { useDynamicAppLayout } from "legacy/hooks/useDynamicAppLayout";
import useOpenContextMenuForWidget from "legacy/pages/Editor/WidgetContextMenu/useOpenContextMenuForWidget";
import { getApplicationSidebarKey } from "legacy/selectors/editorPreferencesSelector";
import { getIsLeftPanePinned } from "legacy/selectors/editorSelectors";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { setFileState } from "legacy/widgets/FilepickerWidget/FilePickerSingleton";
import { useAppDispatch } from "store/helpers";
import { selectAiModalOpen } from "store/slices/ai/selectors";
import { getApplicationSettings } from "store/slices/application/selectors";
import { selectOnlyOrganization } from "store/slices/organizations";
import { rejectById, resolveById } from "store/utils/resolveIdSingleton";
import {
  iframeMessageHandler,
  sendMessage,
  setChildRef,
  IframeEventHandler,
  reportStats,
  updateStats,
  setStatsReporting,
  unregisterIframeRef,
} from "utils/iframe";
import { cleanNotification } from "utils/notification";
import { useCopyPasteWidgets } from "../../../hooks/ui/useCopyPasteWidgets";
import { getResponsiveCanvasScaleFactor } from "../../selectors/applicationSelectors";
import { useGroupWidgets } from "../common/useGroupWidgets";
import type { AppState } from "store/types";

const PageView = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-x: auto;
  overflow-y: auto;
  border: none;
  &.hidden {
    visibility: hidden;
  }

  &[data-sidebar-open="true"] {
    pointer-events: none;
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DragOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

type AppPageProps = {
  appName?: string;
  backgroundColor?: string;
  hidden?: boolean;
};

// TODO move this into a new single bus we have for all iframe communication
// currently using this to send messages to the iframe once its ready otherwise hotkeys wont work
// see useGlobalHotkeys hook for how I'm using this. It's not the best but it works until we refactor.
export const postMessageQueue: { type: "register-keypress"; payload: any }[] =
  [];

const IframeWrapper = (props: AppPageProps) => {
  const dispatch = useAppDispatch();
  usePauseTimersOnTabSleep();

  const store = useStore<AppState>();

  const frameRef = useRef<HTMLIFrameElement>(null);
  const [isFrameLoaded, setLoaded] = useState(false);

  const shouldDisplayOverheadStats = useMemo(() => {
    const value = window.location.search.includes("sb_iframe_stats=true");
    setStatsReporting(value);
    return value;
  }, []);
  useInterval(() => {
    reportStats();
  }, 5_000);

  const user = useSelector(getCurrentUser);
  const organization = useSelector(selectOnlyOrganization);

  // If the sidebar is open and not pinned, the iframe doesn't recieve clicks until it gets dismissed
  const currentApplicationSidebar = useSelector(getApplicationSidebarKey);
  const isLeftPanePinned = useSelector(getIsLeftPanePinned);

  useCopyPasteWidgets(false);
  useGroupWidgets({ disabled: false });

  const openContextMenuForWidget = useOpenContextMenuForWidget();

  useEffect(() => {
    const sendFrameInit = () => {
      const state = store.getState();
      const user = getCurrentUser(state);
      const organization = selectOnlyOrganization(state);
      const featureFlags = state.featureFlags;

      sendMessage({
        type: "init",
        payload: {
          redux: {
            legacy: state.legacy,
            application: state.application,
            featureFlags,
            ai: state.ai,
          },
          logContext: {
            superblocks_user_id: user?.id,
            superblocks_user_email: user?.email,
            superblocks_org_id: organization.id,
            superblocks_org_name: organization.name,
          },
          keysToRegister: postMessageQueue.map(({ payload }) => payload),
        },
      });
    };

    const handleReady: IframeEventHandler<"ready"> = ({ data }) => {
      updateStats("ready", data.startTime);
      const state = store.getState();
      sendMessage({
        type: "register-custom-components",
        payload: {
          components: getApplicationSettings(state)?.registeredComponents ?? {},
        },
      });
      dispatch(appFrameLoaded());
      setLoaded(true);
      sendFrameInit();
    };

    const handleIframeAction: IframeEventHandler<"iframe-action"> = ({
      data,
    }) => {
      updateStats(data.payload.type, data.startTime);
      dispatch({ ...data.payload, isChildAction: true });
    };
    const handleIframeActionBatch: IframeEventHandler<
      "iframe-action-batch"
    > = ({ data }) => {
      updateStats(
        data.payload.length === 1
          ? `batch ${data.payload[0].type}`
          : `batch of ${data.payload.length}`,
        data.startTime,
      );
      if (shouldDisplayOverheadStats && data.payload.length > 1) {
        console.log(
          "[] stats",
          data.payload.map((a) => a.type),
        );
      }
      for (const action of data.payload) {
        if (
          shouldDisplayOverheadStats &&
          (action.type === setMetaProp.type ||
            action.type === setMetaProps.type)
        ) {
          console.log("[] stats", action.payload);
        }
        dispatch({ ...action, isChildAction: true });
      }
    };
    const handleFilePickerAction: IframeEventHandler<
      "filepicker-singleton"
    > = ({ data }) => {
      updateStats("filepicker", data.startTime);
      setFileState(data.payload);
    };
    const handlePromiseResolution: IframeEventHandler<"resolve-promise"> = ({
      data,
    }) => {
      updateStats("promise", data.startTime);
      resolveById(data.callbackId, data.payload);
    };
    const handlePromiseRejection: IframeEventHandler<"reject-promise"> = ({
      data,
    }) => {
      updateStats("promise", data.startTime);
      rejectById(data.callbackId, data.payload);
    };

    const handleConfigReload: IframeEventHandler<"reload-configs"> = ({
      data,
    }) => {
      updateStats("reload", data.startTime);
      window.location.reload();
    };
    iframeMessageHandler.addEventListener("reload-configs", handleConfigReload);

    const handleHotReload: IframeEventHandler<"hot-reloaded"> = ({ data }) => {
      dispatch({
        type: ReduxActionTypes.SET_LAST_HOT_RELOAD_TIME,
      });
      updateStats("hot-reloaded", data.startTime);
    };
    iframeMessageHandler.addEventListener("hot-reloaded", handleHotReload);

    const handleNavigateTo: IframeEventHandler<"navigate-to"> = ({ data }) => {
      updateStats("navigate-to", data.startTime);
      if (data.payload.newWindow) {
        window.open(data.payload.url, "_blank");
      } else {
        window.location.href = data.payload.url;
      }
    };

    const handleOpenWidgetContextMenu: IframeEventHandler<
      "open-widget-context-menu"
    > = ({ data }) => {
      // Adjust the x,y based on iframe location
      const iframe = document.querySelector('[data-test="sb-iframe"]');
      if (!iframe) {
        return;
      }
      const rect = iframe.getBoundingClientRect();

      const adjustedX = rect.left + data.payload.clientX;
      const adjustedY = rect.top + data.payload.clientY;

      openContextMenuForWidget({
        widgetId: data.payload.widgetId,
        pasteAtCursorInsertionIndexes:
          data.payload.pasteAtCursorInsertionIndexes,
        clientX: adjustedX,
        clientY: adjustedY,
      });
    };

    iframeMessageHandler.addEventListener("ready", handleReady);
    iframeMessageHandler.addEventListener("iframe-action", handleIframeAction);
    iframeMessageHandler.addEventListener(
      "iframe-action-batch",
      handleIframeActionBatch,
    );
    iframeMessageHandler.addEventListener(
      "filepicker-singleton",
      handleFilePickerAction,
    );
    iframeMessageHandler.addEventListener(
      "resolve-promise",
      handlePromiseResolution,
    );
    iframeMessageHandler.addEventListener(
      "reject-promise",
      handlePromiseRejection,
    );
    iframeMessageHandler.addEventListener("navigate-to", handleNavigateTo);
    iframeMessageHandler.addEventListener(
      "open-widget-context-menu",
      handleOpenWidgetContextMenu,
    );

    // send all clicks happening in root element to the iframe
    const onClickInRootElement = () => {
      sendMessage({ type: "click" });
    };
    window.addEventListener("pointerdown", onClickInRootElement);

    return () => {
      window.removeEventListener("pointerdown", onClickInRootElement);

      iframeMessageHandler.removeEventListener("ready", handleReady);
      iframeMessageHandler.removeEventListener(
        "iframe-action",
        handleIframeAction,
      );
      iframeMessageHandler.removeEventListener(
        "iframe-action-batch",
        handleIframeActionBatch,
      );
      iframeMessageHandler.removeEventListener(
        "filepicker-singleton",
        handleFilePickerAction,
      );
      iframeMessageHandler.removeEventListener(
        "resolve-promise",
        handlePromiseResolution,
      );
      iframeMessageHandler.removeEventListener(
        "reject-promise",
        handlePromiseRejection,
      );
      iframeMessageHandler.removeEventListener("navigate-to", handleNavigateTo);
      iframeMessageHandler.removeEventListener(
        "open-widget-context-menu",
        handleOpenWidgetContextMenu,
      );
      iframeMessageHandler.removeEventListener(
        "reload-configs",
        handleConfigReload,
      );
      iframeMessageHandler.removeEventListener("hot-reloaded", handleHotReload);
      unregisterIframeRef();
      cleanNotification(); // Clear all app builder alerts when the iframe unmounts
    };
  }, [dispatch, shouldDisplayOverheadStats, store, openContextMenuForWidget]);

  const frameLoadHandler: React.ReactEventHandler<HTMLIFrameElement> =
    useCallback(() => {
      // The iframe can load multiple times when it remounts, which is not a good UX
      // because it shows the koala spinner while mounting. This logic prevents the infinite
      // koala spinner.
      if (frameRef.current?.contentWindow) {
        setChildRef(frameRef.current.contentWindow);
        sendMessage({ type: "parent-ready" });
      }
    }, []);

  useDynamicAppLayout();
  const canvasScaleFactor = useSelector(getResponsiveCanvasScaleFactor);
  useEffect(() => {
    sendMessage({
      type: "canvas-scale-update",
      payload: { canvasScaleFactor },
    });
  }, [canvasScaleFactor]);

  const isSplitPaneDragging = useSelector(
    (state: AppState) => state.legacy.ui.isSplitPaneDragging,
  );

  const isAiAssistantDragging = useSelector(
    (state: AppState) => state.legacy.ui.isAiAssistantDragging,
  );

  const isAiModalOpen = useSelector(selectAiModalOpen);

  return (
    <div>
      {isAiModalOpen ? <AiComponentEditorWrapper /> : null}
      <PageView
        data-test="sb-iframe"
        title={props.appName}
        src={
          envs.get("SUPERBLOCKS_UI_IFRAME_URL") +
          "applications/iframe-edit" +
          `?ui_version=${envs.get("SUPERBLOCKS_UI_VERSION")}` + // cache busting
          (shouldDisplayOverheadStats ? "&sb_iframe_stats=true" : "") +
          `${user?.email ? `&superblocks_user_email=${user.email}` : ""}` +
          `&superblocks_org_name=${organization.name}`
        }
        ref={frameRef}
        onLoad={frameLoadHandler}
        allow="camera;microphone"
        allowFullScreen={true}
        className={!isFrameLoaded || props.hidden ? "hidden" : undefined}
        data-sidebar-open={!!currentApplicationSidebar && !isLeftPanePinned}
      />
      {isSplitPaneDragging || isAiAssistantDragging ? (
        <DragOverlay id="sb-drag-overlay" />
      ) : null}
      {!isFrameLoaded ? (
        <SpinnerContainer style={{ backgroundColor: props.backgroundColor }}>
          <FullPageSpinner useNonBrandedSpinner={true} />
        </SpinnerContainer>
      ) : null}
    </div>
  );
};

export default IframeWrapper;
