import { Dimension } from "@superblocksteam/shared";
import { get, isArray, isEqual } from "lodash";
import { WidgetAddChild } from "legacy/actions/pageActions";
import {
  StepDef,
  isValidStepDef,
  ValidMultiStepDef,
  TriggerStepType,
  isValidMultiStepDef,
} from "legacy/constants/ActionConstants";
import { ApisMap } from "legacy/constants/ApiConstants";
import {
  GridDefaults,
  PAGE_WIDGET_ID,
  WidgetTypes,
  WidgetType,
  SectionColumnsByType,
  SectionDefaults,
  WIDGET_PADDING,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import { generateReactKey } from "./generators";
import type {
  DimensionGetter,
  NumGetter,
  WidgetBlueprint,
} from "legacy/mockResponses/WidgetConfigResponse";
import type {
  FlattenedWidgetProps,
  CanvasWidgetsReduxState,
} from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import type { WidgetMap, WidgetProps } from "legacy/widgets";

/**
 * The function calculates the number of grid rows available based on the provided height and minimum height.
 */
export const getCanvasSnapRows = (
  height: Dimension<WidgetHeightModes>,
  minHeight?: Dimension<"gridUnit" | "px">,
): number => {
  const convertToGrid = (
    d: Dimension<"px" | "gridUnit" | "fitContent" | "fillParent">,
  ) =>
    Dimension.toGridUnit(d, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).raw().value;

  return Math.max(
    convertToGrid(height),
    minHeight ? convertToGrid(minHeight) : 0,
  );
};

// we have to use this gridUnitsToHeightPxWithoutWidgetPadding because onUpdateDynamicHeight adds 2*1px padding on the other end
export const gridUnitsToHeightPxWithoutWidgetPadding = (
  heightInGridUnits: number,
) => {
  return (
    heightInGridUnits * GridDefaults.DEFAULT_GRID_ROW_HEIGHT -
    WIDGET_PADDING * 2
  );
};

export function getParentWidgets(
  widgetId: string | undefined,
  widgets: WidgetMap,
): FlattenedWidgetProps[] {
  const parents: FlattenedWidgetProps[] = [];
  if (widgetId) {
    let widget = get(widgets, widgetId, undefined);

    while (widget?.parentId) {
      const parent = widgets[widget.parentId];
      parents.push(parent);

      if (parent?.parentId && parent.parentId !== PAGE_WIDGET_ID) {
        widget = widgets[widget.parentId];
      } else {
        widget = undefined;
      }
    }
  }
  return parents;
}

type WidgetTraversalOptions = {
  includeOrphanedWidgets: boolean;
};

export function getWidgetChildren(
  widgetId: string,
  widgets: WidgetMap,
  options: WidgetTraversalOptions = { includeOrphanedWidgets: true },
): FlattenedWidgetProps[] {
  // When a form widget tries to resetChildrenMetaProperties
  // But one or more of its container like children
  // have just been deleted, widget can be undefined
  if (!(widgetId in widgets)) {
    return [];
  }

  const childrenWidgets: FlattenedWidgetProps[] = [];
  const widget = widgets[widgetId];
  const { children = [] } = widget;
  for (const childId of children) {
    const child = widgets[childId];
    if (
      !options.includeOrphanedWidgets &&
      // the follow check ensures that we skip all modals/slideouts but include
      // canvas widgets that are detached from layout (i.e. not focusable/resizable etc.)
      child.detachFromLayout &&
      child.type !== WidgetTypes.CANVAS_WIDGET
    ) {
      // skip all widgets that are detached like slideouts, modals etc.
      continue;
    }

    childrenWidgets.push(child);
    const recursiveChildren = getWidgetChildren(childId, widgets, options);
    childrenWidgets.push(...recursiveChildren);
  }
  return childrenWidgets;
}

export function getWidgetChildrenIds(
  widgetId: string,
  widgets: CanvasWidgetsReduxState,
  options: WidgetTraversalOptions = { includeOrphanedWidgets: true },
): string[] {
  return getWidgetChildren(widgetId, widgets, options).map(
    (widget) => widget.widgetId,
  );
}

type Update = {
  entityId: string;
  propertyName: string;
  propertyValue: unknown;
};

function deleteReferencesFromTrigger(
  trigger: ValidMultiStepDef | undefined,
  oldName: string,
  isDeletingWidget: boolean,
  widgetId: string,
  key: string,
  updates: Array<Update>,
) {
  if (!isArray(trigger)) {
    return;
  }
  trigger.forEach((step: StepDef, index) => {
    if (!isValidStepDef(step)) return;

    switch (step.type) {
      case TriggerStepType.RUN_APIS: {
        if (!isDeletingWidget) {
          const newValue = step.apiNames.filter((name) => name !== oldName);
          if (!isEqual(step.apiNames, newValue)) {
            updates.push({
              entityId: widgetId,
              propertyName: `${key}[${index}].apiNames`,
              propertyValue: newValue,
            });
          }
        }
        if (step.onSuccess && isValidMultiStepDef(step.onSuccess)) {
          deleteReferencesFromTrigger(
            step.onSuccess,
            oldName,
            isDeletingWidget,
            widgetId,
            `${key}[${index}].onSuccess`,
            updates,
          );
        }
        if (step.onError && isValidMultiStepDef(step.onError)) {
          deleteReferencesFromTrigger(
            step.onError,
            oldName,
            isDeletingWidget,
            widgetId,
            `${key}[${index}].onError`,
            updates,
          );
        }
        break;
      }
      case TriggerStepType.CANCEL_APIS: {
        if (!isDeletingWidget) {
          const newValue = step.apiNames.filter((name) => name !== oldName);
          if (!isEqual(step.apiNames, newValue)) {
            updates.push({
              entityId: widgetId,
              propertyName: `${key}[${index}].apiNames`,
              propertyValue: newValue,
            });
          }
        }
        break;
      }
      case TriggerStepType.CONTROL_MODAL:
      case TriggerStepType.CONTROL_SLIDEOUT: {
        if (isDeletingWidget && step.name === oldName) {
          updates.push({
            entityId: widgetId,
            propertyName: `${key}[${index}].name`,
            propertyValue: "",
          });
        }
        break;
      }
      case TriggerStepType.SET_STATE_VAR:
      case TriggerStepType.RESET_STATE_VAR: {
        if (step.state?.name === oldName) {
          updates.push({
            entityId: widgetId,
            propertyName: `${key}[${index}].state`,
            propertyValue: undefined,
          });
        }
        break;
      }
      case TriggerStepType.RESET_COMPONENT: {
        if (isDeletingWidget && step.widget?.name === oldName) {
          updates.push({
            entityId: widgetId,
            propertyName: `${key}[${index}].widget`,
            propertyValue: undefined,
          });
        }
        break;
      }
      case TriggerStepType.SET_COMPONENT_PROPERTY: {
        if (isDeletingWidget && step.widget?.name === oldName) {
          updates.push({
            entityId: widgetId,
            propertyName: `${key}[${index}].widget`,
            propertyValue: "",
          });
        }
        break;
      }
      case TriggerStepType.CONTROL_TIMER: {
        if (isDeletingWidget && step.name === oldName) {
          updates.push({
            entityId: widgetId,
            propertyName: `${key}[${index}].name`,
            propertyValue: "",
          });
        }
        break;
      }
      case TriggerStepType.RUN_JS:
      case TriggerStepType.NAVIGATE_TO:
      case TriggerStepType.NAVIGATE_TO_APP:
      case TriggerStepType.NAVIGATE_TO_ROUTE:
      case TriggerStepType.SET_QUERY_PARAMS:
      case TriggerStepType.SHOW_ALERT:
      case TriggerStepType.TRIGGER_EVENT:
      case TriggerStepType.SET_PROFILE:
      case TriggerStepType.RUN_SUPERBLOCKS_OPTIMIZED_APIS:
        break;
      default: {
        const exhaustiveCheck: never = step;
        throw new Error(`Unhandled action case: ${exhaustiveCheck}`);
      }
    }
  });
}

export function deleteReferencesFromWidgetTriggers(
  widgets: WidgetMap,
  oldName: string,
  isDeletingWidget: boolean,
): Array<Update> | undefined {
  const updates: Array<Update> = [];

  for (const [widgetId, widget] of Object.entries(widgets)) {
    if (widget.widgetName === oldName) {
      return;
    }

    if (widget.dynamicTriggerPathList) {
      widget.dynamicTriggerPathList.forEach(({ key }) => {
        const trigger: ValidMultiStepDef | undefined = get(widget, key);
        deleteReferencesFromTrigger(
          trigger,
          oldName,
          isDeletingWidget,
          widgetId,
          key,
          updates,
        );
      });
    }
  }

  if (updates.length) {
    return updates;
  }
}

export function deleteReferencesFromApiInfoTriggers(
  apisMap: ApisMap,
  oldName: string,
  isDeletingWidget: boolean,
): Array<Update> | undefined {
  const updates: Array<Update> = [];

  for (const [apiId, apiInfo] of Object.entries(apisMap)) {
    if (apiInfo.onSuccess && isValidMultiStepDef(apiInfo.onSuccess)) {
      deleteReferencesFromTrigger(
        apiInfo.onSuccess,
        oldName,
        isDeletingWidget,
        apiId,
        "onSuccess",
        updates,
      );
    }
    if (apiInfo.onError && isValidMultiStepDef(apiInfo.onError)) {
      deleteReferencesFromTrigger(
        apiInfo.onError,
        oldName,
        isDeletingWidget,
        apiId,
        "onError",
        updates,
      );
    }
    if (apiInfo.onCancel && isValidMultiStepDef(apiInfo.onCancel)) {
      deleteReferencesFromTrigger(
        apiInfo.onCancel,
        oldName,
        isDeletingWidget,
        apiId,
        "onCancel",
        updates,
      );
    }
    if (apiInfo.onMessage && isValidMultiStepDef(apiInfo.onMessage)) {
      deleteReferencesFromTrigger(
        apiInfo.onMessage,
        oldName,
        isDeletingWidget,
        apiId,
        "onMessage",
        updates,
      );
    }
  }

  if (updates.length) {
    return updates;
  }
}

export const getSectionColsForParentType = (widgetType: WidgetType): number => {
  return SectionColumnsByType[widgetType] || SectionDefaults.SECTION_COLUMNS;
};

export const getMaxGridColsForParentType = (widgetType: WidgetType): number => {
  if (widgetType === WidgetTypes.SLIDEOUT_WIDGET) {
    return GridDefaults.SLIDEOUT_DEFAULT_GRID_COLUMNS;
  }
  return GridDefaults.DEFAULT_GRID_COLUMNS;
};

export const getSectionGridRowsForParentType = (
  widgetType: WidgetType,
): number => {
  return (
    SectionDefaults.DEFAULT_SECTION_GRID_ROWS_BY_PARENT_TYPE[widgetType] ||
    SectionDefaults.DEFAULT_SECTION_GRID_ROWS
  );
};

export const dimensionToGridRows = (
  d: Dimension<WidgetHeightModes>,
): Dimension<"gridUnit"> => {
  return Dimension.gridUnit(
    Dimension.toGridUnit(d, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).roundUp()
      .value,
  );
};

function getPosValue(
  start: NumGetter | undefined,
  end: NumGetter | undefined,
  length: NumGetter | DimensionGetter | undefined,
  parent: WidgetProps,
): Dimension<"gridUnit"> {
  const cStart = typeof start === "function" ? start(parent) : start;
  const cEnd = typeof end === "function" ? end(parent) : end;
  const cLength = typeof length === "function" ? length(parent) : length;
  if (cStart !== undefined) {
    return cStart;
  }
  if (cLength !== undefined && cLength.mode !== "gridUnit") {
    return Dimension.gridUnit(0);
  }
  if (cEnd !== undefined && cLength !== undefined) {
    return Dimension.minus(cEnd, cLength as Dimension<"gridUnit">).asFirst();
  }
  return Dimension.gridUnit(0);
}

function getSizeValue(
  size: NumGetter | DimensionGetter | undefined,
  parent: WidgetProps,
) {
  return typeof size === "function"
    ? size(parent)
    : size ?? Dimension.gridUnit(0);
}

export function buildView(
  view: WidgetBlueprint["view"],
  widgetId: string,
  parent: WidgetProps,
): WidgetAddChild[] {
  const children: WidgetAddChild[] = [];
  if (view) {
    for (const template of view) {
      //TODO(abhinav): Can we keep rows and size mandatory?
      try {
        const pos = template.position;
        const size = template.size;
        children.push({
          widgetId,
          type: template.type,
          position: {
            left: getPosValue(pos.left, pos.right, size?.width, parent),
            top: getPosValue(pos.top, pos.bottom, size?.height, parent),
          },
          size: {
            width: getSizeValue(size?.width, parent),
            height: getSizeValue(size?.height, parent),
          },
          newWidgetId: generateReactKey(),
          props: template.props,
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  return children;
}
